import React from "react"
import styled from 'styled-components';

import { useGlobalStateContext, useGlobalDispatchContext } from '../context/globalContext'

import Layout from "../components/layout"
import SEO from "../components/seo"

import backgroundImg from '../images/background404.jpg'

const NotFoundWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  background-image: url(${backgroundImg});
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
`

const TitleWrapper = styled.div`
  text-align: center;
  transform: rotate(-10deg);

h1 {
    font-family: 'Dancing Script', cursive;
    font-size: 12rem;
    text-shadow: -6px 6px 1px #141414;
  }

  p {
    font-family: 'Dancing Script', cursive;
    font-size: 3.5rem;
    text-shadow: -4px 4px 1px #141414;
  }
`


const NotFoundPage = (props) => {

  const { cursorStyles } = useGlobalStateContext()
  const dispatch = useGlobalDispatchContext()

  const onCursor = (cursorType) => {
    cursorType = (cursorStyles.includes(cursorType) && cursorType) || false;
    dispatch({ type: 'CURSOR_TYPE', cursorType: cursorType })
  }

  return (
  <Layout>
    <SEO title="404: Strona nie istnieje" />

    <NotFoundWrapper>
      <TitleWrapper>
        <h1>404</h1>
        <p>Wpisałeś ścieżkę która nie istnieje... niestety</p>
      </TitleWrapper>
    </NotFoundWrapper>
   </Layout>
  )
}

export default NotFoundPage
